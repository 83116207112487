<script>
import tableColumnsCache from "@/utils/tableColumnsCache"
import {UTable, UTh, UTr, UTd, UCheckbox, date, UTooltip} from 'uloc-vue'
import MenuOptions from "@/components/layout/context-menu/context-window-options"
import {EBtnTableOptions} from "uloc-vue-plugin-erp"
import MenuOptionsItem from "@/components/layout/context-menu/context-window-options-item"
import ECol from "@/components/layout/components/Col"
import ERow from "@/components/layout/components/Row"
import {datePtToEn} from "@/utils/date"
import SearchFilter from "@/components/layout/helpers/SearchFilter"
import DateInput from "@/reuse/input/Date";
import {ErpInput, ErpSField, ErpCheckbox, ErpSelect} from 'uloc-vue-plugin-erp'
import {downloadDocumento, listControleRgi, deleteControleRgi} from "@/domain/controleRgi/services"
import docWindow from './window/documento'
import {differenceInHours, parseISO} from "date-fns"

const listName = 'controle.rgi.lista'
const listStorage = tableColumnsCache(listName, [
  {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
  {label: 'Solicitante', name: 'solicitante', active: true, sortable: true, ordering: 1},
  {label: 'Matricula', name: 'matricula', active: true, sortable: true, ordering: 1},
  {label: 'Transcrição', name: 'transcricao', active: true, sortable: true, ordering: 1},
  {label: 'CRI', name: 'cri', active: true, sortable: true, ordering: 1},
  {label: 'Comarca', name: 'comarca', active: true, sortable: true, ordering: 1},
  {label: 'Processo', name: 'processo', active: true, sortable: true, ordering: 1},
  {label: 'Solicitação', name: 'dataSolicitacao', active: true, sortable: true, ordering: 1},
  {label: 'Pedido', name: 'dataPedido', active: true, sortable: true, ordering: 1},
  {label: 'Entrega', name: 'dataEntrega', active: true, sortable: true, ordering: 1},
  {label: 'Status', name: 'status', active: true, sortable: true, ordering: 1},
], 1)

let filters = {
  id: null,
  busca: null,
  data1: '',
  data2: '',
  tipoData: 'solicitacao',
  processo: null,
  solicitante: null,
  matricula: null,
  transcricao: null,
  cri: null,
  comarca: null,
  status: 0
}

export default {
  name: "ControleRgiList",
  components: {
    DateInput,
    SearchFilter,
    ERow,
    ECol,
    MenuOptionsItem,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    UTooltip,
    ErpSField,
    ErpInput,
    ErpSelect,
    // ErpCheckbox
  },
  mixins: [],
  data() {
    return {
      listStorage: listStorage,
      listType: 'ativos',
      // isBuscaPeriodo: false,
      table: {
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20,
          sortBy: 'createdAt',
          descending: false
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    menuDashOptions() {
      return MenuOptions
    },
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    isBuscaPeriodo () {
      return this.table.filters.data1 && this.table.filters.data1.length && this.table.filters.data2 && this.table.filters.data2.length
    }
  },
  created () {
  },
  mounted () {
    this.load()
  },
  methods: {
    request({pagination, filter}) {
      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10) {
          alert('Digite a data corretamente para o filtro.')
          return
        }
        data1 = datePtToEn(this.table.filters.data1)
        if (this.isBuscaPeriodo) {
          data2 = datePtToEn(this.table.filters.data2)
          if (!date.isValid(data1) || !date.isValid(data2)) {
            alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
            return
          }
        } else {
          if (!date.isValid(data1)) {
            alert('Digite a data corretamente. Formato: dd/mm/yyyy')
            return
          }
          data2 = null
        }
        extraFilters.push(`&data1=${data1}`)
        data2 && extraFilters.push(`&data2=${data2}`)
      }

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.busca && extraFilters.push(`&search=${this.table.filters.busca}`)
      this.table.filters.solicitante && extraFilters.push(`&solicitante=${this.table.filters.solicitante.id}`)
      this.table.filters.status !== null && extraFilters.push(`&status=${this.table.filters.status}`)
      this.table.filters.tipoData && extraFilters.push(`&tipoData=${this.table.filters.tipoData}`)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'name'}&descending=${pagination.descending ? 'true' : 'false'}${extraFilters.join('')}`)

      listControleRgi(pagination.rowsPerPage, pagination.page, filtros)
          .then((response) => {
            let data = response.data
            this.loading = false
            this.table.loading = false
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.table.loading = false
            this.loading = false
          })
    },
    load(format = null) {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca || ''
      })
    },
    listOptions () {
      console.log('Allons-y!!')
    },
    docWindow,
    novo () {
      this.docWindow()
    },
    abrir (id) {
      this.docWindow(id)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.busca
      })
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir rgi-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteControleRgi(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Documento excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    testDue (d) {
     return  Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    vencido (doc) {
      return this.testDue(doc.dataPedido) > 0
    },
    downloadArquivo (documento) {
      downloadDocumento(documento.id, null, true)
          .then(response => {
            console.log(response.data)
            const link = document.createElement('a')
            link.href = response.data.url
            link.target = '_blank'
            document.body.appendChild(link)
            link.click();
            window.setTimeout(() => {
              document.body.removeChild(link)
            }, 100)
            // window.open(response.data.url)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>

<template>
  <div class="m-t">
    <div class="erp-list full-width">
      <p class="font-16 font-bold">Controle de RGI</p>
      <search-filter style="margin: 10px 0">
        <form class="m-t-sm">
          <e-row class="e-input-modern size1 def-m-r">
            <e-col>
              <erp-s-field label="Titular">
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row class="e-input-modern size1 def-m-r">
            <e-col>
              <erp-s-field view="tl" label="Pesquisa inteligente">
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F2" placeholder="Solicitante, processo, matrícula..." v-model="table.filters.busca" />
              </erp-s-field>
            </e-col>

            <e-col style="min-width: 116px; max-width: 116px">
              <date-input label="Data (De)" v-model="table.filters.data1" @keyup.native.enter="pesquisar" />
            </e-col>

            <e-col class="col" style="min-width: 116px; max-width: 116px">
              <date-input label="Data (Até)" v-model="table.filters.data2" @keyup.native.enter="pesquisar" />
            </e-col>

            <e-col class="col" style="min-width: 116px; max-width: 116px">
              <erp-s-field
                  view="tl"
                  label="Tipo Data"
              >
                <erp-select
                    placeholder="Qualquer"
                    :options="[
                        {label: 'Solicitação', value: 'solicitacao'},
                        {label: 'Pedido', value: 'pedido'},
                        {label: 'Entrega', value: 'entrega'},
                    ]"
                    v-model="table.filters.tipoData" />
              </erp-s-field>
            </e-col>
            <e-col class="col" style="min-width: 216px; max-width: 216px">
              <erp-s-field
                  view="tl"
                  label="Status"
              >
                <erp-select
                    placeholder="Qualquer"
                    :options="[
                        {label: 'Pendente de entrega', value: 0},
                        {label: 'Entregue', value: 1},
                        {label: 'Todos', value: null},
                    ]"
                    v-model="table.filters.status" />
              </erp-s-field>
            </e-col>

            <div class="col flex justify-end m-t-sm def-list-buttons" style="min-width: 220px">
              <div class="flex flex-center column m-r-xs">
                <u-btn class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']" @shortkey.native="novo"
                       @click="novo" />
                <span class="shortcut">Shift + N</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                       @click="pesquisar" />
                <span class="shortcut">Shift + Enter</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                       @shortkey.native="clearFilters" />
                <span class="shortcut">Ctrl + Shift + R</span>
              </div>
            </div>
          </e-row>
        </form>
      </search-filter>

      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="certidoes-table"
          :rows-per-page-options="[20, 50, 100]"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th style="text-transform: none" v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
            <div v-if="col.name === 'options'" class="text-center">
              <u-btn disabled style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
            </div>
            <u-tooltip v-if="col.alt">{{col.alt}}</u-tooltip>
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer_" slot="body" slot-scope="props"
              :props="props"> <!-- :class="{vencido: vencido(props.row)}" -->
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
            {{ props.row.id }}
          </u-td>
          <u-td key="solicitante" :props="props">
            <a>
              <div v-if="props.row.solicitante" class="person-avatar-name">
                <div class="person-avatar avatar md inline">
                  <img v-if="props.row.solicitante.photo" width="25" class="" :src="props.row.solicitante.photo"/>
                  <div v-else class="img-fake">{{ firstLettersName(props.row.solicitante.name) }}</div>
                </div>
                <span class="name">{{props.row.solicitante.name}}</span>
              </div>
            </a>
          </u-td>
          <u-td key="matricula" :props="props">
            {{ props.row.matricula }}
          </u-td>
          <u-td key="transcricao" :props="props">
            {{ props.row.transcricao }}
          </u-td>
          <u-td key="cri" :props="props">
            {{ props.row.cri }}
          </u-td>
          <u-td key="comarca" :props="props">
            {{ props.row.comarca }}
          </u-td>
          <u-td key="processo" :props="props">
            <div v-if="props.row.processo">{{ props.row.processo.numero|formataNumeroProcesso }}</div>
          </u-td>
          <u-td key="dataSolicitacao" :props="props" style="word-break: break-word; white-space: break-spaces">
            <span v-if="props.row.createdAt">{{ props.row.createdAt|formatDate('dd/MM/yyyy') }}</span>
            <span v-else>-</span>
          </u-td>
          <u-td key="dataPedido" :props="props" style="word-break: break-word; white-space: break-spaces">
            <span v-if="props.row.dataPedido">{{ props.row.dataPedido.date|formatDate('dd/MM/yyyy') }}</span>
            <span v-else>-</span>
          </u-td>
          <u-td key="dataEntrega" :props="props" style="word-break: break-word; white-space: break-spaces">
            <span v-if="props.row.dataEntrega">{{ props.row.dataEntrega.date|formatDate('dd/MM/yyyy') }}</span>
            <span v-else>-</span>
          </u-td>
          <u-td key="status" :props="props">
            <span v-if="props.row.status === 1" class="u-chip u-chip-small bg-green text-white font-bold font-12">Entregue</span>
            <span v-else class="u-chip u-chip-small bg-negative text-white font-bold font-12">Pendente</span>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <a @click="downloadArquivo(props.row)" class="m-r">
              <i class="fas fa-file-download text-black font-14" />
              <u-tooltip :offset="[5,5]">Baixar</u-tooltip>
            </a>
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Abrir" @click="abrir(props.row.id)" icon="folder-open" />
                  <menu-options-item close label="Excluir" @click="excluir(props.row.id)" icon="times" />
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>
    </div>
  </div>
</template>
